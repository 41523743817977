import { FeatureFlagsMutation } from "~/store/featureFlags";
import { fetchMonetateOmniJson } from "~/services/monetate.service";
import { TrackingEvents } from "~/store/tracking";

export default (context) => {
  const { app, store, $config } = context;

  // Fetch the Monetate OmniJson experiences and store features flags in Vuex
  async function monetateOmniJson(to) {
    const path = `${$config.appUrl}${to.path}`;

    try {
      const res = await fetchMonetateOmniJson(context, path);

      if (!res) {
        throw new Error("response not available from monetate");
      }

      if (!Array.isArray(res.actions)) {
        res.actions = [res];
      }

      res.actions.forEach((monetateFlag) => {
        if (Object.keys(monetateFlag).length !== 0) {
          // Merge Monetate flags into store
          store.commit(
            `featureFlags/${FeatureFlagsMutation.SetFlags}`,
            monetateFlag
          );
        }
      });

      if (process.server && res?.trackingData) {
        // Set the omni tracking data in vuex so we can then fetch on the client and push to the dataLayer
        setInitialOmniTrackingData(res.trackingData);
      }

      return res;
    } catch (err) {
      console.warn("Monetate omniJson unable to retrieve data", err);
    }
  }

  // Triggers a dataLayer push with the Monetate experience data
  function handleTracking(actions, callback = () => {}) {
    // Timeout as we need to wait for the next event loop tick
    setTimeout(() => {
      actions.forEach((action) => {
        action.impressionReporting.forEach((impression) => {
          store.commit("tracking/addTrackingEvent", {
            type: TrackingEvents.MonetateExperienceDecision,
            data: impression,
          });
        });
      });
      callback();
    });
  }

  // Sets and clears the initial serverside tracking data in Vuex
  function setInitialOmniTrackingData(data = []) {
    store.commit(
      `featureFlags/${FeatureFlagsMutation.ServerOmniTrackingData}`,
      data
    );
  }

  app.router.beforeEach(async (to, _from, next) => {
    const serverOmniTrackingData =
      store.getters["featureFlags/serverOmniTrackingData"];
    // This runs only once on the client when the app initially loads to handle GTM tracking
    if (serverOmniTrackingData?.length && process.client) {
      handleTracking(serverOmniTrackingData, setInitialOmniTrackingData);
      next();
      return;
    }
    await monetateOmniJson(to)
      .then((res) => {
        if (process.server) return;

        if (res && res?.trackingData) {
          handleTracking(res.trackingData);
        }
      })
      .catch((error) =>
        console.error("Error fetching Monetate omni JSON", error)
      );
    next();
  });
};
