<template>
  <div class="shadow h-full">
    <nuxt-link
      :to="`/${product.url.replace('https://www.toolstation.com/', '')}`"
      class="h-full flex flex-col"
      @click="trackClick"
    >
      <div class="flex flex-col items-center bg-white py-3 relative">
        <!-- Brand Icon -->
        <img
          v-if="badgeIcons && badgeIcons.brand && badgeIcons.brand.imageUrl"
          :src="badgeIcons.brand.imageUrl"
          loading="lazy"
          :alt="badgeIcons.brand ? badgeIcons.brand.altText : ''"
          class="absolute left-2 top-2 w-[60px]"
        />

        <!-- Badge -->
        <img
          v-if="badgeIcons && badgeIcons.badge && badgeIcons.badge.imageUrl"
          :src="badgeIcons.badge.imageUrl"
          loading="lazy"
          :alt="badgeIcons.badge.altText"
          class="absolute right-2 top-2 w-[60px]"
        />
        <img
          class="mb-2 h-[175px] w-auto p-4"
          :src="imageUrl"
          :alt="title"
          loading="lazy"
        />
        <RatingStars
          class="mt-2 mb-4"
          :rating="reviews"
          color="yellow"
          :image="false"
        />
      </div>
      <div class="flex flex-col items-center px-4 py-6 h-full justify-center">
        <span class="text-center text-size-6">{{ title }}</span>
        <span class="font-semibold mt-4 text-legacy-blue-default text-size-7"
          >£{{ grossPrice.toFixed(2) }}</span
        >
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import RatingStars from "~/components/UI/rating-stars/RatingStars.vue";

export default {
  components: { RatingStars },
  props: {
    product: {
      type: Object,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    reviews: {
      type: Number,
      required: true,
    },
    grossPrice: {
      type: Number,
      required: true,
    },
    netPrice: {
      type: Number,
      required: true,
    },
    badgeIcons: {
      type: Object,
      required: true,
    },
  },
  methods: {
    trackClick() {
      this.$emit("trackClickPixel", {
        product: this.product.pid,
      });
    },
  },
};
</script>
