
import Vue from "vue";
import { getStars, getRatingWidth } from "~/lib/product/";

export default Vue.extend({
  props: {
    starWidth: {
      type: String,
      default: "18px",
    },
    rating: {
      type: [String, Number],
      default: 0,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
    color: {
      type: String,
      default: "yellow",
      validator: (val) => ["yellow", "blue"].includes(String(val)),
    },
    size: {
      type: String,
      default: "text-size-6.5",
    },
  },
  computed: {
    starUrl() {
      switch (this.color) {
        case "blue":
          return require("~/assets/svg/blue-star.svg");
        case "yellow":
          return require("~/assets/svg/yellow-star.svg");
        default:
          return "";
      }
    },
  },
  methods: {
    getStars,
    getRatingWidth,
  },
});
