export default {
  methods: {
    quantityLabel(item) {
      if (!("quantitylabel" in item)) {
        return this.$t("search.trolleyQty");
      }

      try {
        const parsedLabels = JSON.parse(item.quantitylabel);
        return parsedLabels.desktop;
      } catch (e) {
        console.warn(`quantitylabel contains invalid JSON for ${item.pid}`);
        return {};
      }
    },
    quantityMax(item) {
      return item.quantitymaximum || 999;
    },
    quantityMin(item) {
      let parsedValue = "";

      if (item.quantityminimum) {
        parsedValue = JSON.parse(item.quantityminimum);
      }
      return (item.quantityminimum && parsedValue.value) || 1;
    },
  },
};
