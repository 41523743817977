<template>
  <div>
    <h2
      v-if="title"
      class="pathways_recommendations__title text-size-6 md:text-size-8 font-bold text-legacy-blue-default mb-2 md:mb-6"
      :class="styleType === 'version 2' ? 'text-left' : 'text-center'"
    >
      {{ title }}
    </h2>
    <!-- Grid Display -->
    <div v-if="displayAsGrid">
      <Grid
        class="hidden lg:grid"
        :show-badges="showBadges"
        :card-type="cardType"
        :show-add-to-trolley="showAddToTrolley"
        cta
        :is-loading="isLoading"
        :show-flash="showFlash"
        :data="data"
        @trackClickPixel="trackClickPixel"
      />

      <!-- Always display as carousel on mobile -->
      <Carousel
        class="block lg:hidden"
        :show-badges="showBadges"
        :card-type="cardType"
        :show-add-to-trolley="showAddToTrolley"
        :cta="cta"
        :show-flash="showFlash"
        :data="data"
        :slides-per-page="slidesPerPage"
        :is-loading="isLoading"
        @trackClickPixel="trackClickPixel"
      />
    </div>

    <!-- Carousel Display -->
    <div v-else>
      <Carousel
        :show-badges="showBadges"
        :card-type="cardType"
        :show-add-to-trolley="showAddToTrolley"
        :cta="cta"
        :show-flash="showFlash"
        :data="data"
        :slides-per-page="slidesPerPage"
        :is-loading="isLoading"
        @trackClickPixel="trackClickPixel"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { pluck } from "ramda";

// Layout Styles
import { nextTick } from "vue";
import Carousel from "./layouts/Carousel.vue";
import Grid from "./layouts/Grid.vue";

import {
  trackProductImpressions,
  trackProductClicks,
} from "~/services/monetate.service";
import { TrackingEvents } from "~/store/tracking";

export default {
  name: "ProductRecommendations",
  components: { Grid, Carousel },
  props: {
    data: {
      type: Object,
      required: true,
    },
    displayAsGrid: {
      type: Boolean,
      required: false,
      default: false,
    },
    styleType: {
      type: String,
      required: false,
      default: "version 1",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    autoPlayDelay: {
      type: Number,
      required: false,
      default: 6,
    },
    slidesPerPage: {
      type: Object,
      required: false,
      default: () => {
        return {
          desktop: 4,
          tablet: 3,
          mobile: 1,
        };
      },
    },
    showAddToTrolley: {
      type: Boolean,
      required: false,
      default: false,
    },
    cta: {
      type: String,
      required: false,
      default: "",
    },
    showBadges: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFlash: {
      type: Boolean,
      required: false,
      default: false,
    },
    provider: {
      type: String,
      required: false,
      default: "bloomreach",
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      trackingViewSentAndStockUpdated: false,
    };
  },
  computed: {
    ...mapGetters("stock", ["getStock"]),
    ...mapGetters("search", ["getBrandUrl"]),
    cardType() {
      switch (this.styleType) {
        case "version 1":
          return "CardVersionOne";
        case "version 2":
          return "CardVersionTwo";
        default:
          return "CardVersionOne";
      }
    },
  },
  watch: {
    data: function (newVal, _oldVal) {
      this.trackViewAndUpdateStock(newVal);
    },
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, _state) => {
      if (mutation.type === "branch/setSelectedBranch") {
        this.updateStock();
      }
    });

    nextTick(() => {
      this.trackViewAndUpdateStock(this.data);
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    trackViewAndUpdateStock(data) {
      if (this.trackingViewSentAndStockUpdated || !data?.data?.length) {
        return;
      }

      this.trackingViewSentAndStockUpdated = true;

      this.trackViewPixel();
      this.updateStock();
    },
    updateStock() {
      if (!this.showAddToTrolley || this.styleType !== "version 2") return;
      this.$store.dispatch("stock/additionalStockLookup", {
        products: pluck("pid", this.$props.data.data),
      });
    },
    trackViewPixel() {
      const trackingData = {
        ...this.data,
        ...this.data?.tracking,
        showAddToTrolley: this.showAddToTrolley || false,
      };

      if (this.provider === "monetate") {
        const recTokens = this.data.data.map((product) => product.recToken);

        trackProductImpressions(this, recTokens).catch((err) =>
          console.log(err)
        );

        this.$store.commit("tracking/addTrackingEvent", {
          type: TrackingEvents.PathwaysAndRecsViewPixel,
          data: {
            ...trackingData,
            provider: "monetate",
          },
        });

        return;
      }

      if (this.provider === "bloomreach") {
        this.$store.commit("tracking/addTrackingEvent", {
          type: TrackingEvents.PathwaysAndRecsViewPixel,
          data: {
            ...trackingData,
            provider: "bloomreach",
          },
        });

        return;
      }

      if (this.provider === "google") {
        this.$store.commit("tracking/addTrackingEvent", {
          type: TrackingEvents.PathwaysAndRecsViewPixel,
          data: {
            ...trackingData,
            provider: "google",
          },
        });
      }
    },
    trackClickPixel(eventData) {
      const pid = eventData.product;
      const trackingData = {
        ...this.data?.tracking,
        ...eventData,
        addToCart: eventData.addToCart || false,
      };

      if (this.provider === "monetate") {
        const recToken = this.data.data.find(
          (product) => product.pid === pid
        ).recToken;

        trackProductClicks(this, [recToken]);

        this.$store.commit("tracking/addTrackingEvent", {
          type: TrackingEvents.PathwaysAndRecsClickPixel,
          data: {
            ...trackingData,
            provider: "monetate",
          },
        });
      }

      if (this.provider === "bloomreach") {
        this.$store.commit("tracking/addTrackingEvent", {
          type: TrackingEvents.PathwaysAndRecsClickPixel,
          data: {
            ...trackingData,
            provider: "bloomreach",
          },
        });
      }

      if (this.provider === "google") {
        this.$store.commit("tracking/addTrackingEvent", {
          type: TrackingEvents.PathwaysAndRecsClickPixel,
          data: {
            ...trackingData,
            provider: "google",
          },
        });
      }

      this.$emit("pathwaysClick", pid);
    },
  },
};
</script>
