var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.containerClasses},[(_vm.showCollectionButton)?_c('div',{staticClass:"flex flex-col w-full",class:[
      { 'col-span-2': !_vm.showDeliveryButton },
      { 'justify-end': _vm.displayStockLocation !== 'below' },
    ]},[(_vm.showQuantity || _vm.displayStockLocation === 'above')?_c('div',{staticClass:"flex mb-2 quantity-location-wrapper"},[(_vm.showMessaging)?_c('ProductCollectionStockMessage',{attrs:{"is-next-day-collection":_vm.isNextDayDelivery,"product-code":_vm.getProductCode,"pdp-layout":_vm.pdpLayout}}):_vm._e(),_vm._v(" "),(_vm.showQuantity)?_c('ProductQuantity',{attrs:{"product":_vm.product,"compute-min-quantity-from-attrs":""},on:{"quantityChange":_vm.updateQuantity}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('DefaultButton',{staticClass:"flex relative justify-center items-center",class:[
        _vm.largeSize ? 'h-[56px]' : 'h-[40px]',
        { 'mb-[48px]': !_vm.showDeliveryButton && !_vm.showIcon },
      ],attrs:{"data-testid":"add-to-trolley-collection-button","disabled":_vm.isCollectionDisabled,"variant":_vm.isNextDayDelivery ? 'primary' : 'tertiary',"icon-classes":_vm.iconClasses('collection'),"size":_vm.largeSize ? 'md' : 'small',"with-icon":!_vm.showIcon
          ? ''
          : _vm.isNextDayDelivery
            ? 'icons/next-business-day_web.svg'
            : 'icons/collection_web.svg'},on:{"clickAction":function($event){return _vm.addForCollection()}}},[_c('span',{staticClass:"font-semibold leading-none text-center capitalize"},[_vm._v("\n        "+_vm._s(_vm.isNextDayDelivery
            ? _vm.$tc("search.nextDayCollection")
            : _vm.$tc("search.collection"))+"\n      ")])]),_vm._v(" "),(_vm.displayStockLocation === 'below' && _vm.showMessaging)?_c('ProductCollectionStockMessage',{staticClass:"mt-2 text-[14px] leading-none cursor-pointer",attrs:{"product-code":_vm.getProductCode,"pdp-layout":_vm.pdpLayout,"is-next-day-collection":_vm.isNextDayDelivery}}):_vm._e(),_vm._v(" "),(_vm.isNextDayDelivery && _vm.showSeeFullDetails)?_c('client-only',[_c('DefaultButton',{staticClass:"text-blue text-left underline cursor-pointer leading-1 text-[14px] mt-1",attrs:{"size":"sm","variant":"none"},on:{"clickAction":_vm.showNextBusinessDayModal}},[_vm._v("\n        "+_vm._s(_vm.$tc("search.seeFullDetailsBtn"))+"\n      ")])],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showDeliveryButton)?_c('div',{staticClass:"flex flex-col w-full",class:[
      { 'col-span-2': !_vm.showCollectionButton },
      { 'justify-end': _vm.displayStockLocation !== 'below' },
    ]},[_c('div',{staticClass:"message-qty-wrapper"},[(_vm.displayStockLocation === 'above' && _vm.showMessaging)?_c('ProductDeliveryStockMessage',{staticClass:"md:h-[34px] mt-2",attrs:{"product":_vm.product,"product-code":_vm.getProductCode,"pdp-layout":_vm.pdpLayout}}):_vm._e(),_vm._v(" "),(!_vm.showCollectionButton && _vm.showQuantity)?_c('ProductQuantity',{attrs:{"product":_vm.product,"compute-min-quantity-from-attrs":""},on:{"quantityChange":_vm.updateQuantity}}):_vm._e()],1),_vm._v(" "),_c('DefaultButton',{staticClass:"relative flex items-center justify-center add-to-trolley-delivery-button",class:[
        _vm.largeSize ? 'h-[56px]' : 'h-[40px]',
        {
          'mb-6':
            _vm.isNextDayDelivery &&
            _vm.showSeeFullDetails &&
            _vm.displayStockLocation !== 'below',
        },
        { 'justify-start': _vm.pdpAltLayout },
        { 'mt-[48px]': !_vm.showCollectionButton && !_vm.showIcon },
      ],attrs:{"data-testid":"add-to-trolley-delivery-button","disabled":_vm.isDeliveryDisabled,"variant":"tertiary","size":_vm.largeSize ? 'md' : 'small',"icon-classes":_vm.iconClasses('delivery'),"with-icon":!_vm.showIcon ? '' : 'icons/delivery_web.svg'},on:{"clickAction":function($event){return _vm.addForDelivery()}}},[_c('span',{staticClass:"font-semibold leading-none text-left capitalize"},[_vm._v("\n        "+_vm._s(_vm.$tc("delivery"))+"\n      ")])]),_vm._v(" "),(_vm.displayStockLocation === 'below' && _vm.showMessaging)?_c('ProductDeliveryStockMessage',{staticClass:"mt-2",attrs:{"product":_vm.product,"product-code":_vm.getProductCode,"pdp-layout":_vm.pdpLayout}}):_vm._e(),_vm._v(" "),(_vm.jumpToDeliveryInformation)?_c('ExternalLink',{attrs:{"classes":"text-blue text-[14px] underline","link-text":_vm.$t('directship-more-info'),"url":"#deliverydetails"},on:{"clickAction":function($event){return _vm.$emit('jumpToDeliveryInformationClicked')}}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }