
import Vue from "vue";
import { mapGetters } from "vuex";
import { isDropship, getDropshipDeliveryTime } from "~/lib/product";

export default Vue.extend({
  name: "ProductDeliveryStockMessage",

  props: {
    product: {
      type: Object,
      required: true,
    },
    productCode: {
      type: String,
      required: true,
    },
    pdpLayout: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters("branch", ["selectedBranchId", "selectedBranchName"]),
    ...mapGetters("stock", [
      "hasStockForDelivery",
      "hasStockForSite",
      "stockForCollection",
      "stockForDelivery",
    ]),
    availableForDelivery() {
      return (
        this.hasStockForDelivery(this.productCode) &&
        !isDropship(this.product) &&
        !this.product?.weekdayDeliveryOnly
      );
    },

    showChristmasMessaging() {
      return (
        this.availableForDelivery &&
        this.$config.inChristmasPeriod &&
        this.pdpLayout
      );
    },
  },

  methods: {
    getDeliveryStockMessage(): string {
      if (isDropship(this.product)) {
        return this.$tc("search.deliveryOnly");
      }

      if (!this.hasStockForDelivery(this.productCode)) {
        return this.$tc("search.outOfStockForDelivery");
      }

      const stock = this.stockForDelivery(this.productCode);

      return this.$tc("search.availableForDelivery", 0, {
        stock: stock > 20 ? "20+" : stock,
      });
    },

    getPDPDeliveryStockMessage(): string {
      const stock = this.stockForDelivery(this.productCode);

      if (!this.hasStockForDelivery(this.productCode)) {
        return this.$tc("product.outOfStockForDelivery");
      }

      if (isDropship(this.product)) {
        const dropshipDeliveryTime = getDropshipDeliveryTime(
          this.product.otherAttributes ?? []
        );

        return (
          ` <span class="font-bold">${this.$tc(
            "product.availableForDelivery",
            0,
            {
              stock: stock > 20 ? "20+" : stock,
            }
          )}</span> ` +
          this.$tc("product.dropshipAvailableForDelivery", 0, {
            dropshipDeliveryTime: dropshipDeliveryTime?.value,
          })
        );
      }

      if (this.product?.weekdayDeliveryOnly) {
        return (
          ` <span class="font-bold">${this.$t(
            "product.availableForDelivery",
            "0",
            {
              stock: stock > 20 ? "20+" : stock,
            }
          )}</span> ` + this.$t("product.upToTwoBusinessDays")
        );
      }

      return (
        ` <span class="font-bold">${this.$tc(
          "product.availableForDelivery",
          0,
          {
            stock: stock > 20 ? "20+" : stock,
          }
        )}</span>` + this.$tc("product.deliveryOrderBefore")
      );
    },
  },
});
