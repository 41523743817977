import { camelCase } from "camel-case";
import { is } from "ramda";
import { ROUTE_SCRIPTS_CONFIG } from "~/constants/siteScripts";

/**
 * Test whether an object looks like a promises-a+ promise
 *
 * isPromise(Promise.resolve());//=>true
 * isPromise({then:function () {...}});//=>true
 * isPromise(null);//=>false
 * isPromise({});//=>false
 * isPromise({then: true})//=>false
 *
 * @param {object} obj test object
 * @returns boolean true or false
 */
export function isPromise(obj) {
  return (
    !!obj &&
    (typeof obj === "object" || typeof obj === "function") &&
    typeof obj.then === "function"
  );
}

export function validationRulePhoneNumber(locale) {
  let regex = /^(\+?44|0)7\d{9}$/;

  switch (locale) {
    case "en-GB":
      regex = /^(\+?44|0)7\d{9}$/;
      break;
    case "en-FR":
      regex = /^(\+?33|0)[67]\d{8}$/;
      break;
    case "en-NL":
      regex = /^(((\+|00)?31\(0\))|((\+|00)?31)|0)6{1}\d{8}$/;
      break;
    case "nl-BE":
      regex = /^(\+?32|0)4\d{8}$/;
      break;
    case "fr-FR":
      regex = /^(\+?33|0)[67]\d{8}$/;
      break;
    case "nl-NL":
      regex = /^(((\+|00)?31\(0\))|((\+|00)?31)|0)6{1}\d{8}$/;
      break;
    default:
      break;
  }

  return regex;
}

const creditCard =
  /^(?:4[0-9]{12}(?:[0-9]{3,6})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12,15}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|6[27][0-9]{14}|^(81[0-9]{14,17}))$/;
/* eslint-enable max-len */
// Thanks to all contributors of
// https://github.com/validatorjs/validator.js/blob/master/src/lib/isCreditCard.js
export function isCreditCard(str) {
  const sanitized = str.replace(/[- ]+/g, "");

  if (!creditCard.test(sanitized)) {
    return false;
  }

  let sum = 0;
  let digit;
  let tmpNum;
  let shouldDouble;

  for (let i = sanitized.length - 1; i >= 0; i--) {
    digit = sanitized.substring(i, i + 1);
    tmpNum = parseInt(digit, 10);

    if (shouldDouble) {
      tmpNum *= 2;

      if (tmpNum >= 10) {
        sum += (tmpNum % 10) + 1;
      } else {
        sum += tmpNum;
      }
    } else {
      sum += tmpNum;
    }

    shouldDouble = !shouldDouble;
  }

  return !!(sum % 10 === 0 ? sanitized : false);
}

/**
 * List of country codes with
 * corresponding IBAN regular expression
 * Reference: https://en.wikipedia.org/wiki/International_Bank_Account_Number
 */
const ibanRegexThroughCountryCode = {
  BE: /^(BE[0-9]{2})\d{12}$/,
  FR: /^(FR[0-9]{2})\d{10}[A-Z0-9]{11}\d{2}$/,
  GB: /^(GB[0-9]{2})[A-Z]{4}\d{14}$/,
  NL: /^(NL[0-9]{2})[A-Z]{4}\d{10}$/,
};

/**
 * Check whether string has correct universal IBAN format
 * The IBAN consists of up to 34 alphanumeric characters, as follows:
 * Country Code using ISO 3166-1 alpha-2, two letters
 * check digits, two digits and
 * Basic Bank Account Number (BBAN), up to 30 alphanumeric characters.
 * NOTE: Permitted IBAN characters are: digits [0-9] and the 26 latin alphabetic [A-Z]
 *
 * @param {string} str - string under validation
 * @return {boolean}
 */
function hasValidIbanFormat(str) {
  // Strip white spaces and hyphens
  const strippedStr = str.replace(/[\s-]+/gi, "").toUpperCase();
  const isoCountryCode = strippedStr.slice(0, 2).toUpperCase();

  return (
    isoCountryCode in ibanRegexThroughCountryCode &&
    ibanRegexThroughCountryCode[isoCountryCode].test(strippedStr)
  );
}

/**
 * Check whether string has valid IBAN Checksum
 * by performing basic mod-97 operation and
 * the remainder should equal 1
 * -- Start by rearranging the IBAN by moving the four initial characters to the end of the string
 * -- Replace each letter in the string with two digits, A -> 10, B = 11, Z = 35
 * -- Interpret the string as a decimal integer and
 * -- compute the remainder on division by 97 (mod 97)
 * Reference: https://en.wikipedia.org/wiki/International_Bank_Account_Number
 *
 * @param {string} str
 * @return {boolean}
 */
function hasValidIbanChecksum(str) {
  const strippedStr = str.replace(/[^A-Z0-9]+/gi, "").toUpperCase(); // Keep only digits and A-Z latin alphabetic
  const rearranged = strippedStr.slice(4) + strippedStr.slice(0, 4);
  const alphaCapsReplacedWithDigits = rearranged.replace(
    /[A-Z]/g,
    (char) => char.charCodeAt(0) - 55
  );

  const remainder = alphaCapsReplacedWithDigits
    .match(/\d{1,7}/g)
    .reduce((acc, value) => Number(acc + value) % 97, "");

  return remainder === 1;
}

export function isIBAN(str) {
  return hasValidIbanFormat(str) && hasValidIbanChecksum(str);
}

export function randomRequestId() {
  return Math.floor(Math.random() * 9999999999999);
}

// https://stackoverflow.com/a/50620653
export function camelizeKeys(object) {
  if (Array.isArray(object)) {
    return object.map((v) => camelizeKeys(v));
  }
  if (is(Object, object)) {
    return Object.keys(object).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(object[key]),
      }),
      {}
    );
  }
  return object;
}

export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object));
}

export function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function maskString(str) {
  return str.replace(/(?<=\S)\S(?=\S)/g, "*");
}

export const getAvailableScripts = (scripts, route) => {
  const routeConfig = ROUTE_SCRIPTS_CONFIG[route];

  if (!routeConfig) {
    return scripts;
  }

  return scripts.filter(
    (script) => !routeConfig.disabledScripts.includes(script.hid)
  );
};
