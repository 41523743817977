export enum ScriptId {
  ONE_TRUST = "one-trust",
  ONE_TRUST_EXTERNAL = "one-trust-external",
}

export const ROUTE_SCRIPTS_CONFIG = {
  "checkout-delivery": {
    disabledScripts: [ScriptId.ONE_TRUST, ScriptId.ONE_TRUST_EXTERNAL],
  },
  "checkout-review": {
    disabledScripts: [ScriptId.ONE_TRUST, ScriptId.ONE_TRUST_EXTERNAL],
  },
};
