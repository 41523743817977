<template>
  <div
    class="relative flex flex-col h-full p-4 overflow-hidden text-left bg-white border-1 border-grey-3 gap-3"
    data-testid="product-reccomendation-card"
    @click="trackClick"
  >
    <nuxt-link
      :to="`/${product.slug}/p${product.pid}`"
      class="text-blue text-size-4"
    >
      <div
        v-if="(savingValue.length || webOverlayText) && showFlash"
        class="absolute top-3 -right-10 z-10 flex justify-center items-center bg-legacy-red-ts-red min-h-[35px] rotate-45 text-white font-heavy text-[13px] leading-[24px] w-[140px] uppercase"
        :class="savingTextClasses"
      >
        <p v-if="savingValue.length" :class="savingTextClasses">
          <span>{{ $tc("search.priceSave") }}</span>
          <span>{{ savingText }}</span>
        </p>
        <p
          v-if="!savingValue.length && webOverlayText"
          class="w-1/2 mx-auto text-center break-words leading-[15px] px-1"
        >
          {{ webOverlayText }}
        </p>
      </div>

      <div class="relative flex justify-center w-full py-3">
        <!-- Brand Icon -->
        <img
          v-if="badgeIcons && badgeIcons.brand && badgeIcons.brand.imageUrl"
          :src="badgeIcons.brand.imageUrl"
          loading="lazy"
          :alt="badgeIcons.brand.altText"
          class="absolute left-0 top-0 w-[60px]"
        />

        <!-- Badge -->
        <img
          v-if="badgeIcons && badgeIcons.brand && badgeIcons.brand.imageUrl"
          :src="badgeIcons.badge.imageUrl"
          loading="lazy"
          :alt="badgeIcons.badge.altText"
          class="absolute right-0 top-0 w-[60px]"
        />
        <img
          :src="imageUrl"
          :alt="title"
          width="128"
          height="128"
          loading="lazy"
        />
      </div>
      <span>{{ title }}</span>
    </nuxt-link>
    <div class="flex">
      <RatingStars :rating="reviews" size="text-size-5" />
    </div>
    <p class="flex flex-col mt-auto text-blue">
      <span class="text-[18px] font-bold">£{{ grossPrice.toFixed(2) }}</span>
      <!-- <span class="text-size-2">ex. VAT £{{ netPrice.toFixed(2) }}</span> -->
    </p>
    <ProductAddToTrolley
      v-if="showAddToTrolley"
      class="mt-auto gap-2"
      :quantity="quantity"
      :product="product"
      :show-icon="false"
      :vertical-orientation="true"
      :show-messaging="false"
      :show-see-full-details="false"
      @addToCartButtonClicked="trackAddToCart"
    />

    <div v-else-if="cta">
      <DefaultButton
        appearance="pill"
        variant="tertiary"
        size="small"
        :path="ExtractPathFromUrl(product.url)"
        class="w-full text-center md:w-auto"
      >
        {{ cta }}
      </DefaultButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RatingStars from "~/components/UI/rating-stars/RatingStars.vue";
import ProductAddToTrolley from "~/components/products/ProductAddToTrolley.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import quantityControls from "~/components/product-listing/body/products/controls/quantityControls";
import { ExtractPathFromUrl } from "~/utils/urlTransformations";

export default {
  components: { RatingStars, ProductAddToTrolley, DefaultButton },
  mixins: [quantityControls],
  props: {
    product: {
      type: Object,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    reviews: {
      type: Number,
      required: true,
    },
    grossPrice: {
      type: Number,
      required: true,
    },
    netPrice: {
      type: Number,
      required: true,
    },
    showAddToTrolley: {
      type: Boolean,
      required: false,
      default: false,
    },
    cta: {
      type: String,
      required: false,
      default: "",
    },
    badgeIcons: {
      type: Object,
      required: true,
    },
    savingValue: {
      type: Array,
      required: false,
      default: () => [],
    },
    webOverlayText: {
      type: String,
      required: false,
      default: "",
    },
    showFlash: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters("search", ["findQuantities"]),

    quantity() {
      if (this.product?.quantityminimum?.value) {
        return parseInt(this.product?.quantityminimum?.value);
      }

      const qty = this.findQuantities(
        this.$props.product.pid,
        this.quantityMin(this.$props.product)
      );

      return parseInt(qty);
    },
    isSaveText() {
      return this.savingValue[0] === this.$tc("search.priceSave");
    },
    savingText() {
      return this.isSaveText ? this.savingValue[1] : this.savingValue[0];
    },
    savingTextClasses() {
      return this.isSaveText
        ? "flex-col leading-[12px]"
        : "flex-row leading-[24px] gap-1";
    },
  },
  methods: {
    ExtractPathFromUrl,
    trackClick(event) {
      const targetEvent = event?.target?.dataset?.testid;
      const parentEvent = event?.target?.parentElement?.dataset?.testid;
      const applicableEvent = (testid) =>
        testid === "add-to-trolley-collection-button" ||
        testid === "add-to-trolley-delivery-button";
      const match =
        applicableEvent(targetEvent) || applicableEvent(parentEvent);

      if (match) return;

      this.$emit("trackClickPixel", {
        product: this.product.pid,
      });
    },
    trackAddToCart(event) {
      this.$emit("trackClickPixel", {
        product: this.product.pid,
        addToCart: true,
        addToCartType: event.buttonType,
      });
    },
  },
};
</script>
