
import Vue, { PropType } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isEmpty } from "ramda";
import Panel from "~/components/UI/panel/Panel.vue";
import FormLabel from "~/components/UI/forms/FormLabel.vue";
import FormInput from "~/components/UI/forms/FormInput.vue";
import FormCheckbox from "~/components/UI/forms/FormCheckbox.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import { procardConfig } from "~/services/config.service";
import { postCustomerLogin } from "~/services/customers.service";
import { AppCookieNames } from "~/constants/ecomApi";
import Divider from "~/components/UI/Divider.vue";
import { LoadingSpinnerIcon } from "~/components/UI/icons";
import { asyncCallWithTimeout } from "~/lib";
import { TrackingEvents } from "~/store/tracking";

interface AutoLoginDetails {
  email: string;
  password: string;
}

export default Vue.extend({
  name: "LoginForm",

  components: {
    DefaultButton,
    FormCheckbox,
    FormLabel,
    FormInput,
    Panel,
    Divider,
    LoadingSpinnerIcon,
  },

  props: {
    redirectTo: {
      type: String as PropType<string>,
      required: false,
      default: "/account/home",
    },
    query: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    isLoginModal: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    autoLoginDetails: {
      type: Object as PropType<AutoLoginDetails>,
      required: false,
      default: () => ({}) as AutoLoginDetails,
    },
  },

  async asyncData({ $config }) {
    const defaultCountryCode = $config.localeInstance;
    const config = await procardConfig(defaultCountryCode);
    return { config };
  },

  data() {
    return {
      email: "",
      password: "",
      errorMessages: [] as string[],
      config: null,
      loading: false,
      showBlankPasswordMessage: false,
    };
  },

  computed: {
    ...mapGetters("trolley", ["trolleyLines", "trolleyId"]),
    ...mapGetters("guest", ["getShowGuestForm"]),
  },
  async mounted() {
    await this.$recaptcha.init();

    if (this.autoLoginDetails) {
      const { email, password } = this.autoLoginDetails;
      if (!email || !password) return;

      this.email = email;
      this.password = password;

      this.submitLoginForm();
    }
  },
  beforeDestroy() {
    this.$recaptcha.destroy();
  },
  methods: {
    isEmpty,
    ...mapActions("auth", ["loginUser"]),
    ...mapMutations("tracking", ["addTrackingEvent"]),
    ...mapMutations("auth", ["setLoginModalVisibility"]),
    async submitLoginForm() {
      this.loading = true;
      this.showBlankPasswordMessage = false;
      this.errorMessages = [];

      const isGuest = this.$store.getters["guest/getGuest"];

      if (isGuest) {
        this.$store.dispatch("guest/clearGuestUser", this.$nuxt.context);
      }

      let recaptchaToken = "";

      try {
        recaptchaToken = await asyncCallWithTimeout(
          this.$recaptcha.execute("login"),
          1000
        );
      } catch (e) {
        console.warn(e);
      }

      await postCustomerLogin(this.$axios, {
        username: this.email,
        password: this.password,
        recaptchaToken,
      })
        .then(async (customer) => {
          await this.$store
            .dispatch("auth/loginUser", {
              context: this.$nuxt.context,
              customer,
            })
            /**
             * If the user has a trolley, we need convert the guest trolley to a customer trolley
             * If the user has no trolley, we need to search for previous trolleys
             */
            .then(async () => {
              if (this.trolleyLines.length > 0 && !isGuest) {
                await this.$store.dispatch(
                  "trolley/convertGuestTrolleyToCustomerTrolley",
                  {
                    trolleyId: this.trolleyId,
                    sessionToken: this.$cookies.get(
                      AppCookieNames.GuestCheckoutSession
                    ),
                  }
                );
              } else {
                await this.$store.dispatch("trolley/findLatestTrolley");
              }
            });
        })
        .then(() => {
          this.isLoginModal
            ? this.setLoginModalVisibility(false)
            : this.$router.push(`${this.redirectTo}?${this.query}`);

          this.addTrackingEvent({
            type: TrackingEvents.UserLoggedIn,
          });
        })

        .catch((err) => {
          this.loading = false;
          if (err.status === 403) {
            if (err?.data?.error?.data?.error_code === "TS403-AE") {
              this.showBlankPasswordMessage = true;

              return;
            }
          }

          if (err.status === 429) {
            this.errorMessages.push(this.$tc("login.tooManyAttempts"));
            return;
          }

          this.errorMessages.push(
            this.$tc("login.incorrectUsernameOrPassword")
          );
        });
    },
  },
});
