
import Vue from "vue";
import { isEmpty, isNil } from "ramda";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ProductCollectionStockMessage",

  props: {
    productCode: {
      type: String,
      required: true,
    },
    pdpLayout: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNextDayCollection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters("branch", ["selectedBranchId", "selectedBranchName"]),
    ...mapGetters("stock", [
      "hasStockForDelivery",
      "hasStockForSite",
      "stockForCollection",
      "stockForDelivery",
    ]),
    isStoreSet(): boolean {
      return isEmpty(this.selectedBranchId) || isNil(this.selectedBranchId);
    },

    getPDPCollectionStockMessage(): string {
      if (this.isStoreSet) {
        return (
          `<span class="text-blue underline">${this.$tc(
            "product.setStore"
          )}</span>` +
          ` ${this.$tc("product.availableToCollect")}` +
          ` <span class="font-bold">${this.$tc(
            "product.collectWithinTime"
          )} </span>`
        );
      }

      if (this.isNextDayCollection) {
        const deliveryStock = this.stockForDelivery(this.productCode);
        const collectionStock = this.stockForCollection(
          this.productCode,
          this.selectedBranchId
        );
        if (collectionStock < deliveryStock) {
          const stock = deliveryStock;

          return (
            this.$tc("product.collectionNextBusinessDay", 0, {
              stock: stock > 20 ? "20+" : stock,
              selectedBranchName: this.selectedBranchName,
            }) + `${this.$tc("product.readyToCollect")}`
          );
        }
      }

      if (!this.hasStockForSite(this.productCode, this.selectedBranchId)) {
        // not in stock, allowed to order for next day
        if (this.hasStockForDelivery(this.productCode)) {
          const stock = this.stockForDelivery(this.productCode);
          return (
            this.$tc("product.collectionNextBusinessDay", 0, {
              stock: stock > 20 ? "20+" : stock,
              selectedBranchName: this.selectedBranchName,
            }) + `${this.$tc("product.readyToCollect")}`
          );
        }
        if (this.selectedBranchName) {
          return (
            this.$tc("product.collectionOutOfStock") +
            ` <span class="font-bold">${this.selectedBranchName}</span>`
          );
        }
        return this.$tc("product.collectionNoStock");
      }
      // In stock
      const stock = this.stockForCollection(
        this.productCode,
        this.selectedBranchId
      );

      return (
        this.$tc("product.stockInStore", 0, {
          stock:
            stock > 20
              ? ` <span class="font-bold">20+</span> `
              : ` <span class="font-bold">${stock}</span> `,
        }) +
        ` <span class="font-bold">${this.$tc(
          "product.collectWithinTime"
        )}</span> `
      );
    },
  },

  methods: {
    async openBranchModal(): Promise<void> {
      await this.$store.dispatch("branch/openBranchModal");
    },

    getCollectionStockMessage(): string {
      if (this.isStoreSet) {
        return this.$tc("search.setBranch");
      }

      if (!this.hasStockForSite(this.productCode, this.selectedBranchId)) {
        // not in stock, allowed to order for next day
        if (this.hasStockForDelivery(this.productCode)) {
          const stock = this.stockForDelivery(this.productCode);
          return this.$tc("search.collectionNextBusinessDay", 0, {
            stock: stock > 20 ? "20+" : stock,
            selectedBranchName: this.selectedBranchName,
          });
        }
        if (this.selectedBranchName) {
          return (
            this.$tc("search.collectionOutOfStock") +
            ` <span class="font-bold">${this.selectedBranchName}</span>`
          );
        }
        return this.$tc("search.collectionNoStock");
      }
      // In stock
      const stock = this.stockForCollection(
        this.productCode,
        this.selectedBranchId
      );
      return (
        this.$tc("search.stockInStore", 0, {
          stock: stock > 20 ? "20+" : stock,
        }) + ` <span class="font-bold">${this.selectedBranchName}</span>`
      );
    },
  },
});
