
// @TODO - This was taken from components/UI/modals/variations-modal/VariationsModal.vue we also have quantity on the
//  search page and product which could be refactored to use this
import Vue, { PropType } from "vue";
import { clamp } from "ramda";
import debounce from "lodash.debounce";
import FormInput from "~/components/UI/forms/FormInput.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import { MinusIcon, PlusIcon } from "~/components/UI/icons";
import { getQtyMinimum } from "~/lib/product";
import { Product } from "~/types/product";

export default Vue.extend({
  name: "ProductQuantity",

  components: {
    DefaultButton,
    FormInput,
    MinusIcon,
    PlusIcon,
  },

  props: {
    startingQuantity: {
      type: Number,
      default: 1,
    },
    product: {
      type: Object as PropType<Product>,
      default: null,
    },
    showQuantityLabel: {
      type: Boolean,
      default: true,
    },
    computeMinQuantityFromAttrs: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      quantity: this.$props.startingQuantity ?? 1,
    };
  },

  fetch() {
    if (this.$props.computeMinQuantityFromAttrs) {
      this.quantity = this.minimumAllowedQuantity;
    }
  },

  computed: {
    minimumAllowedQuantity(): number {
      const attrs = [
        ...(this?.product?.otherAttributes ?? []),
        ...(this?.product?.technicalSpecAttributes ?? []),
      ];
      const qty = getQtyMinimum(attrs) ?? { value: 1 };

      return Number(qty.value);
    },

    maximumAllowedQuantity(): number {
      return 999;
    },
  },

  watch: {
    startingQuantity(quantity) {
      this.quantity = quantity;
    },

    quantity(quantity) {
      this.quantity = clamp(
        this.minimumAllowedQuantity,
        this.maximumAllowedQuantity,
        quantity
      );

      this.debouncedCallback(() => {
        this.$emit("quantityChange", this.quantity);
      });
    },
  },

  methods: {
    debouncedCallback: debounce((callback: Function) => callback(), 700),

    quantityButtonClasses(inc: boolean): object {
      const isDisabled = inc
        ? this.quantity >= this.maximumAllowedQuantity
        : this.quantity <= this.minimumAllowedQuantity;

      return {
        disabled: isDisabled,
        "quantity-button": true,
      };
    },

    decrementQuantity() {
      this.quantity--;
    },

    incrementQuantity() {
      this.quantity++;
    },
  },
});
