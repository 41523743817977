
import Vue, { PropType } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isEmpty, isNil } from "ramda";
import ExternalLink from "../UI/links/ExternalLink.vue";
import DefaultButton from "~/components/UI/button/DefaultButton.vue";
import { isDropship, isOnlineOnly, isTradeCounterOnly } from "~/lib/product";
import { TrackingEvents } from "~/store/tracking";
import ProductQuantity from "~/components/products/ProductQuantity.vue";
import ProductCollectionStockMessage from "~/components/products/ProductCollectionStockMessage.vue";
import ProductDeliveryStockMessage from "~/components/products/ProductDeliveryStockMessage.vue";

enum DisplayStockLocation {
  Below = "below",
  Above = "above",
}

export default Vue.extend({
  name: "ProductAddToTrolley",

  components: {
    ProductDeliveryStockMessage,
    ProductCollectionStockMessage,
    DefaultButton,
    ProductQuantity,
    ExternalLink,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    largeSize: {
      type: Boolean,
      required: false,
      default: false,
    },
    quantity: {
      type: Number,
      required: false,
      default: 1,
    },
    verticalOrientation: {
      type: Boolean,
      required: false,
      default: false,
    },
    pdpLayout: {
      type: Boolean,
      required: false,
      default: false,
    },
    pdpAltLayout: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * If show quantity is false then we will need to pass in a quantity prop
     * If show quantity is true then we will use a component to set the quantity
     */
    showQuantity: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayStockLocation: {
      type: String as PropType<DisplayStockLocation>,
      required: false,
      default: "below",
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    showMessaging: {
      type: Boolean,
      required: false,
      default: true,
    },
    jumpToDeliveryInformation: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSeeFullDetails: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      enableAddCollectionOnBranchWatcher: false,
      enableAddCollectionOnStockWatcher: false,
      addToTrolleyQuantity: this.quantity ?? 1,
    };
  },

  computed: {
    ...mapGetters("branch", ["selectedBranchId", "selectedBranchName"]),
    ...mapGetters("stock", [
      "hasStockForSite",
      "stockForCollection",
      "stockForDelivery",
    ]),
    containerClasses(): string {
      if (this.verticalOrientation) {
        return "grid grid-cols-1 mt-1";
      }
      if (this.pdpLayout) {
        return "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1";
      }
      if (this.pdpAltLayout) {
        const widthClass = this.multipleButtons
          ? "md:w-[400px]"
          : "md:w-[200px]";
        return `grid grid-cols-2 mt-1 w-full ${widthClass} flex items-center`;
      }
      return `grid grid-cols-1 mt-1 sm:grid-cols-2 py-3 sm:min-h-[120px] ${
        this.displayStockLocation === "above" ? "gap-2 md:gap-6" : "gap-2"
      }`;
    },
    showCollectionButton(): boolean {
      return !isDropship(this.product) && !isOnlineOnly(this.product);
    },
    showDeliveryButton(): boolean {
      return !isTradeCounterOnly(this.product);
    },
    multipleButtons(): boolean {
      return this.showCollectionButton && this.showDeliveryButton;
    },
    getProductCode(): string {
      return this.product.code ?? this.product.pid;
    },
    isCollectionDisabled(): boolean {
      // if no store/branch is set, show button as not disabled
      if (isEmpty(this.selectedBranchId) || isNil(this.selectedBranchId)) {
        return false;
      }

      const stockInBranch = Number(
        this.stockForCollection(this.getProductCode, this.selectedBranchId)
      );
      const stockInWarehouse = Number(
        this.stockForDelivery(this.getProductCode)
      );
      const inStockInBranch = stockInBranch >= this.addToTrolleyQuantity;
      const inStockInWarehouse = stockInWarehouse >= this.addToTrolleyQuantity;

      return !inStockInBranch && !inStockInWarehouse;
    },

    isDeliveryDisabled(): boolean {
      const stock = Number(this.stockForDelivery(this.getProductCode));
      return stock < this.addToTrolleyQuantity;
    },
    isStoreSet(): boolean {
      return isEmpty(this.selectedBranchId) || isNil(this.selectedBranchId);
    },
    isNextDayDelivery(): boolean {
      if (isEmpty(this.selectedBranchId) || isNil(this.selectedBranchId)) {
        return false;
      }

      const stockInBranch = Number(
        this.stockForCollection(this.getProductCode, this.selectedBranchId)
      );
      const stockInWarehouse = Number(
        this.stockForDelivery(this.getProductCode)
      );
      const inStockInBranch = stockInBranch >= this.addToTrolleyQuantity;
      const inStockInWarehouse = stockInWarehouse >= this.addToTrolleyQuantity;

      return !inStockInBranch && inStockInWarehouse;
    },
  },
  watch: {
    quantity: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.addToTrolleyQuantity = newVal;
      }
    },

    /**
     * This starts a chain reaction of watchers
     *
     * On add to trolley for collection it enables the enableAddCollectionOnBranchWatcher watcher
     * This will then become disabled and enable the enableAddCollectionOnStockWatcher
     * When enableAddCollectionOnStockWatcher is enabled it will listen for stock changes and add to trolley
     */
    "$store.state.branch.selectedBranchId": function (newVal, oldVal): void {
      if (!this.enableAddCollectionOnBranchWatcher) {
        return;
      }

      if (newVal !== oldVal) {
        this.enableAddCollectionOnBranchWatcher = false;
        this.enableAddCollectionOnStockWatcher = true;
      }
    },

    "$store.state.branch.showBranchModal": function (newVal): void {
      if (!newVal) this.enableAddCollectionOnBranchWatcher = false;
    },

    "$store.state.stock.stockLevels": function (
      mutatedStock,
      previousStockState
    ): void {
      if (
        this.enableAddCollectionOnStockWatcher &&
        previousStockState !== mutatedStock
      ) {
        const hasStockForSite = this.$store.getters["stock/hasStockForSite"](
          this.getProductCode,
          this.selectedBranchId
        );

        if (hasStockForSite) {
          this.enableAddCollectionOnStockWatcher = false;
          this.addForCollection();
        }
      }
    },
  },
  methods: {
    ...mapActions("trolley", ["addingTransaction"]),
    ...mapMutations("search", ["setNextBusinessDayModalVisibility"]),
    iconClasses(type: string) {
      if (this.pdpAltLayout) {
        return "relative h-[20px] w-auto";
      }

      if (type === "collection") {
        return !this.showIcon
          ? ""
          : this.isNextDayDelivery || this.largeSize
            ? `w-auto -mt-[3px] ${
                this.largeSize ? "absolute left-5 h-[20px]" : "h-[16px]"
              }`
            : "h-[12px] w-auto -mt-[1px]";
      }
      if (type === "delivery") {
        return !this.showIcon
          ? ""
          : this.largeSize
            ? `h-[16px] w-auto -mt-[3px] ${
                this.largeSize ? "absolute left-5 h-[20px]" : "h-[16px]"
              }`
            : "h-[14px] w-auto -mt-[3px]";
      }
    },
    showNextBusinessDayModal() {
      this.setNextBusinessDayModalVisibility(true);
    },

    async openBranchModal(): Promise<void> {
      await this.$store.dispatch("branch/openBranchModal", this.getProductCode);
    },

    async addForCollection(): Promise<void> {
      this.$emit("addToCartButtonClicked", {
        buttonType: "collection",
      });

      if (this.isStoreSet) {
        this.enableAddCollectionOnBranchWatcher = true;
        await this.openBranchModal();
        return;
      }

      if (this.isNextDayDelivery) {
        return await this.addingTransaction(async () => {
          await this.$store.dispatch(
            "trolley/addItemToTrolleyForNextDayCollection",
            [this.getProductCode, this.addToTrolleyQuantity]
          );
        });
      }

      return await this.addingTransaction(async () => {
        await this.$store.dispatch("trolley/addItemToTrolleyForCollection", [
          this.getProductCode,
          this.addToTrolleyQuantity,
        ]);

        this.$store.commit("tracking/addTrackingEvent", {
          type: TrackingEvents.AddToCartClick,
          data: {
            type: "collection",
            productName: this.product.name || this.product.title,
          },
        });
      });
    },

    async addForDelivery(): Promise<void> {
      this.$emit("addToCartButtonClicked", {
        buttonType: "delivery",
      });

      if (isDropship(this.product)) {
        return await this.addingTransaction(async () => {
          await this.$store.dispatch("trolley/addItemToTrolleyForDropship", [
            this.getProductCode,
            this.addToTrolleyQuantity,
          ]);
        });
      }

      return await this.addingTransaction(async () => {
        await this.$store.dispatch("trolley/addItemToTrolleyForDelivery", [
          this.getProductCode,
          this.addToTrolleyQuantity,
        ]);

        this.$store.commit("tracking/addTrackingEvent", {
          type: TrackingEvents.AddToCartClick,
          data: {
            type: "delivery",
            productName: this.product.name || this.product.title,
          },
        });
      });
    },

    updateQuantity(value: any) {
      this.addToTrolleyQuantity = value;
    },
  },
});
