<script>
export default {
  functional: true,
  render(h) {
    return h(
      "div",
      { class: "bg-grey-1 h-[425px] p-4 flex flex-col gap-3 rounded-lg" },
      [
        h("div", { class: "content-loading w-full h-3/6" }),
        h("div", { class: "content-loading w-full h-1/6" }),
        h("div", { class: "content-loading w-full h-2/6" }),
      ]
    );
  },
};
</script>
